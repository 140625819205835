import React, { useEffect, useState } from 'react';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Header from '../components/Index/Header';

import axios from 'axios';
import Config from '../config/config';
import { navigate } from 'gatsby';

export default function ResetPassword(props) {

    const [password, setPassword] = useState({x:false, y:false});
    const [error, setError] = useState(false);
    const [feedback, setFeedback] = useState(false);

    useEffect(() => {
        if(error) {
            setTimeout(() => {
                setError(false);
            }, 5000)
        }
    }, [error])

    function changePassword(evt) {

        evt.preventDefault();
        let params = (new URL(window.location)).searchParams;
        let code = params.get('code');

        if(password.x !== password.y) {
            setError('A jelszavak nem egyeznek.')
        }else {
            // Request API.
            axios.post(Config.apiURL + '/auth/reset-password', {
                code: code,
                password: password.x,
                passwordConfirmation: password.y
            }).then(response => {
                // Handle success.
                console.log('Your user\'s password has been changed.');
                setFeedback('Jelszóváltoztatás sikeres. Most visszairányitjuk a bejelentkezéshez.')
                setTimeout(() => {
                    navigate('/profile-authentication')
                }, 2000)
            }).catch(error => {
                // Handle error.
                setError('Jelszóváltoztatás sikertelen');
                console.log('An error occurred:', error.response);
            });
        }
    }

    return(
        <Layout>
            <Navbar />
            <Header />
                <div className="profile-authentication-area ptb-100">
                    <div className="login-form" style={{margin:'20px'}}>
                        <div className="section-title">
                            <h2>Új jelszó beállitása</h2>
                        </div>
                        <form>
                            <div className="form-group">
                                <label>Jelszó</label>
                                <input type="password" className="form-control" placeholder="Jelszó" onChange={(evt) => setPassword({...password, x:evt.target.value})}/>
                            </div>

                            <div className="form-group">
                                <label>Jelszó megerősitáse</label>
                                <input type="password" className="form-control" placeholder="Jelszó megerősitáse" onChange={(evt) => setPassword({...password, y:evt.target.value})}/>
                            </div>
                            <button type="submit" onClick={(evt) => changePassword(evt)}>Új jelszó beállitása</button>
                        </form>
                        {feedback && <div className="error-message-container">
                            <p style={{textAlign:'center', color:'green', padding:'10px'}}>{feedback}</p>
                        </div>}
                        {error && <div className="error-message-container">
                            <p style={{textAlign:'center', color:'red', padding:'10px'}}>{error}</p>
                        </div>}
                    </div>
                </div>
            <Footer />
        </Layout>
    );
}